import {changeAlertStatusAC, changeLoaderMessageAC, changeLoaderStatusAC, checkErrorsAPI} from "./CommonReducer";
import ApiImages from "../../api/apiImages";
import ApiService from "../../api/api";
import {changeOperationsModalValue, changeTemplatesModalValue} from "./ModalReducer";
import {
    addNewTemplateOperationsAC,
    editNewTemplateOperationsAC,
    getPartSizesThunk,
    setPartWithSizesAC
} from "./OrderReducer";
import TemplateInput from "../../pages/Templates/TemplateInput";
import {getTranslateMessageAPI} from "../../helpers/helpers";
import {AVAILABLE_SELECTED_FILTER_OPERATIONS_KEYS, AVAILABLE_SELECTED_OPERATIONS_KEYS} from "../constats/operation-const";
import {LS_IMAGES_INTERNAL} from "../../constants";
import {blueprints} from "../../blueprints/blueprints";

const OPERATIONS_PARTS_ERROR = "OPERATIONS/PARTS_ERROR"

const SET_FIRM_TOOLS = "SET_FIRM_TOOLS";
const SET_OPERATION_IMAGE_SVG_DATA = "SET_OPERATION_IMAGE_SVG_DATA";
const CHANGE_OPERATIONS_SIDE = "CHANGE_OPERATIONS_SIDE";
const SET_HEMS_TYPES_DATA = "SET_HEMS_TYPES_DATA";
const SET_TEMPLATES_TREE_DATA = "SET_TEMPLATES_TREE_DATA";
const SET_TEMPLATE_FORM_DATA = "SET_TEMPLATE_FORM_DATA";
const CHANGE_TEMPLATE_FORM_VALUE_DATA = "CHANGE_TEMPLATE_FORM_VALUE_DATA";


const CHNAGE_BORE_FORM_VALUE = "CHNAGE_BORE_FORM_VALUE";
const CHANGE_BORE_FORM_VALUE_FORMULA = "CHANGE_BORE_FORM_VALUE_FORMULA";
const CLEAN_BORE_FORM = "CLEAN_BORE_FORM";
const EDIT_BORE_DATA = "EDIT_BORE_DATA";

const CHANGE_PAZ_FORM_VALUE = "CHANGE_PAZ_FORM_VALUE";
const CHANGE_PAZ_FORM_VALUE_FORMULA = "CHANGE_PAZ_FORM_VALUE_FORMULA";
const CLEAN_PAZ_FORM = "CLEAN_PAZ_FORM";
const EDIT_PAZ_DATA = "EDIT_PAZ_DATA";

const CHANGE_HEM_FORM_VALUE = "CHANGE_HEM_FORM_VALUE";
const CLEAN_HEM_FORM = "CLEAN_HEM_FORM";
const EDIT_HEM_DATA = "EDIT_HEM_DATA";

const CHANGE_CUT_FORM_VALUE = "CHANGE_CUT_FORM_VALUE";
const CHANGE_CUT_FORM_VALUE_FORMULA = "CHANGE_CUT_FORM_VALUE_FORMULA";
const CLEAN_CUT_FORM = "CLEAN_CUT_FORM";
const EDIT_CUT_DATA = "EDIT_CUT_DATA";

const CHANGE_TROUGH_FORM_VALUE = "CHANGE_TROUGH_FORM_VALUE";
const CHANGE_TROUGH_FORM_VALUE_FORMULA = "CHANGE_TROUGH_FORM_VALUE_FORMULA";
const CLEAN_TROUGH_FORM = "CLEAN_TROUGH_FORM";
const EDIT_TROUGH_DATA = "EDIT_TROUGH_DATA";

const EDIT_TEMPLATE_DATA = "EDIT_TEMPLATE_DATA";

const CHANGE_TEMPLATE_EDIT_FORM_VALUE = "CHANGE_TEMPLATE_EDIT_FORM_VALUE";

const CHANGE_OPERATIONS_MODAL_VALUE = "CHANGE_OPERATIONS_MODAL_VALUE";
const OPENING_DETAILS_FROM_PART = "OPERATIONS/OPENING_DETAILS_FROM_PART";

const OPERATIONS_SELECTED_ADD = "OPERATIONS/SELECTED_ADD";
const OPERATIONS_SELECTED_DELETE = "OPERATIONS/SELECTED_DELETE";
const OPERATIONS_SELECTED_ALL_OFF = "OPERATIONS/SELECTED_ALL_OFF";


const OPERATIONS_FILTER_SELECTED = "OPERATIONS/FILTER_SELECTED";
const OPERATIONS_UPDATE_FILTER_SELECTED = "OPERATIONS/UPDATE_FILTER_SELECTED";
const OPERATIONS_FILTER_ADD_ALL = "OPERATIONS/FILTER_ADD_ALL";
const OPERATIONS_FILTER_REMOVE_ALL = "OPERATIONS/FILTER_REMOVE_ALL";

const apiImages = new ApiImages();
const api = new ApiService();

export const _constructorBore = (form, side, boreID = null) => {
    return {
        id: boreID,
        x1: form.x1,
        y1: form.y1,
        z: form.z,
        d: form.d,
        side: side,
        f_x1: form.formula_x,
        f_y1: form.formula_y,
        f_z: form.formula_z,
        start_point: form?.start_point ?? "ld"
    };
};
export const _constructorPaz = (form, side, pazID = null) => {
    return {
        id: pazID,
        w: form.w,
        type_paz: form.type_paz ? form.type_paz : "0",
        z: form.z,
        w_paz: form.w_paz,
        d: null,
        d1: form.d1,
        side_from: form.side_from ? form.side_from : "t",
        side: side,
        d_side_start: form.d_side_start,
        d_side_end: form.d_side_end,
        f_z: form.formula_z_paz,
        // f_w: form.formula_w_paz,
        f_w: form.formula_w_paz_paz,
        f_w_paz: form.formula_d_paz,
        f_d_side_start: form.formula_d_side_start,
        f_d_side_end: form.formula_d_side_end
    };
};

export const _constructorCutTo = (form, side, cutToID = null) => {
    return {
        id: cutToID,
        t: form.t,
        b: form.b,
        r: form.r,
        l: form.l,
        f_t: form.formula_t,
        f_b: form.formula_b,
        f_r: form.formula_r,
        f_l: form.formula_l
    };
};

export const _constructorTrough = (form, side, troughID = null) => {
    return {
        id: troughID,
        x1: form.x1,
        y1: form.y1,
        z: form.z,
        l: form.l,
        w: form.w,
        r: form.r,
        type_form: form.type_form,
        formula_x: form.formula_x,
        formula_y: form.formula_y,
        formula_z: form.formula_z,
        formula_l: form.formula_l,
        formula_w: form.formula_w,
        side: side
    };
};

export const _constructorHem = (form, side, material_z, hemID = null) => {
    return {
        id: hemID,
        material: form.material,
        typeHem: form.typeHem,
        band: form.band,
        side: side,
        material_z: material_z,
        wide_plank: form.wide_plank,
        hem_txt_back: form.hem_txt_back
    };
};

/// ---------------------------- TEMPLATES ---------------------------- ///


// const _getPartSymbolResult = (symbol, part) => {
//     return symbol && symbol.replace('detail.x1', part.x1)
//                  .replace('detail.x', part.x)
//                  .replace('detail.y1', part.y1)
//                  .replace('detail.y', part.y)
//                  .replace('detail.z', part.z)
//                  .replace('detail.small_side', (Number(part.x) > Number(part.y) ? part.y : part.x))
// }

// const _getTemplateInputType = (type) => {
//     switch (type) {
//         case 'dig':
//             return 'number';
//         case 'str':
//             return 'text';
//         case 'bool':
//             return 'checkbox';
//         case 'select':
//             return 'select';
//     }
// }

// const _getTemplateNumberAccepted = (input, part) => {
//     return input.restriction_by_select.map(e => {

//         var new_max = 0;

//         try {
//             new_max = eval(_getPartSymbolResult(e.max_value, part));
//         } catch (e) {
//             new_max = _getPartSymbolResult(e.max_value, part);
//         }

//         e.max_value = new_max ? new_max : 0;

//         return e;
//     })
// }

// const _getTemplateInputRanges = (type, input, part) => {

//     const ranges = {
//         min: 1,
//         max: 1,
//         accepted: []
//     }

//     if(type !== 'select') {
//         try {
//             ranges.min = eval(_getPartSymbolResult(input.min_value_result,part));
//             ranges.max = eval(_getPartSymbolResult(input.max_value_result, part));
//         } catch (e) {
//             ranges.min = _getPartSymbolResult(input.min_value_result, part);
//             ranges.max = _getPartSymbolResult(input.max_value_result, part);
//         }
//     }

//     return ranges;


// }

export const _constructorTemplate = (inputs, isFrez, part = null) => {
    const templateData = {
        form: null,
        values: null
    };
    try {

        templateData.form = inputs.map(input => {
            const inputTemplate = new TemplateInput(input, isFrez, part);
            return inputTemplate.transform();
        });

        let templateValues = {};
        templateData.form.forEach((f) => {
            templateValues[f.symbol] = 0;
        });
        templateData.values = {...templateValues};
        templateData.isFrez = isFrez;
        return templateData;
    } catch (e) {
        console.log(e.message);
        return templateData
    }

};
export const _constructorEditTemplate = (inputs, isFrez, part, idOperation, idTemplate, alert = null) => {
    let operationValues = isFrez ? part.operations_inputs.frez?.find(frez => Number(frez.id) === Number(idOperation)) : false;
    const templateData = {
        form: null,
        values: null
    };
    templateData.form = inputs.map(input => {
        const inputTemplate = new TemplateInput(input, isFrez, part);
        return inputTemplate.transform();
    });
    let templateValues = {};
    templateData.form.forEach((f) => {
        templateValues[f.symbol] = operationValues && operationValues?.[f.symbol] ? operationValues?.[f.symbol] : 0;
    });
    if (operationValues && operationValues?.hasOwnProperty("db_id") && operationValues?.hasOwnProperty("t")) {
        templateValues.db_id = operationValues?.db_id;
        templateValues.t = operationValues?.t;
    }
    templateData.values = {...templateValues};
    templateData.isFrez = isFrez;
    templateData.alert = alert
    return templateData;
};


/// ---------------------------- ---------------------------- ///

export const _calculateFormula = (formula, part, productionsConst = {}) => {
    let result;
    let replaced = formula.replace("x1", part.x1)
        .replace("x1", part.x1)
        .replace("x1", part.x1)
        .replace("y1", part.y1)
        .replace("y1", part.y1)
        .replace("y1", part.y1)
        .replace("y1", part.y1)
        .replace("y2", part.y2)
        .replace("y2", part.y2)
        .replace("y2", part.y2)
        .replace("y2", part.y2)
        .replace("y2", part.y2)
        .replace("x2", part.x2)
        .replace("x2", part.x2)
        .replace("x2", part.x2)
        .replace("x2", part.x2)
        .replace("x2", part.x2)
        .replace("x", part.x)
        .replace("x", part.x)
        .replace("x", part.x)
        .replace("x", part.x)
        .replace("x", part.x)
        .replace("y", part.y)
        .replace("y", part.y)
        .replace("y", part.y)
        .replace("y", part.y)
        .replace("y", part.y)
        .replace("detail.b_band_thikness", part.edge.b.t ? part.edge.b.t : 0)
        .replace("detail.t_band_thikness", part.edge.t.t ? part.edge.t.t : 0)
        .replace("detail.l_band_thikness", part.edge.l.t ? part.edge.l.t : 0)
        .replace("detail.r_band_thikness", part.edge.r.t ? part.edge.r.t : 0)
        .replace("detail.b_band_pre_joint", 1)
        .replace("detail.t_band_pre_joint", 1)
        .replace("detail.l_band_pre_joint", 1)
        .replace("detail.r_band_pre_joint", 1)
        .replace("detail.small_side", part.x1 > part.y1 ? part.y1 : part.x1)
        .replace("detail.big_side", part.x1 >= part.y1 ? part.x1 : part.y1)
        .replace("small_side", part.x1 > part.y1 ? part.y1 : part.x1)
        .replace("big_side", part.x1 >= part.y1 ? part.x1 : part.y1)
        .replace("production.frez_diametr", Number(productionsConst["production.frez_diametr"] ?? 0))
        .replace("z", part.z);
    ///// Дописать для production части
    try {

        result = eval(replaced);

    } catch (e) {
        result = "";
    }
    return result;
};

let initialState = {
    parts_error: null,
    operationsFilter: {
        bore: [],
        paz: [],
        frez: [],
        trough: [],
        // rest all
        cut_to: [],
        hem: [],
        srez: []
    },
    operationsSelected: {},
    openingDetailsFromPart: {
        partId: null,
        goodsId: null
    },
    partImage: null,
    operationSide: "f",
    operationSides: [
        {id: "f", name: "Лицевая"},
        {id: "bb", name: "Обратная"},
        {id: "t", name: "Верхний торец"},
        {id: "b", name: "Нижний торец"},
        {id: "l", name: "Левый торец"},
        {id: "r", name: "Правый торец"}
    ],
    tools: [],
    hemTypes: [],
    templatesTree: [],
    bore: {
        x1: "",
        y1: "",
        d: "",
        z: "",
        across_x: "",
        across_y: "",
        formula_x: "",
        formula_y: "",
        formula_z: "",
        formula_d: "",
        active_f_x: false,
        active_f_y: false,
        active_f_z: false,
        active_f_d: false,
        isEdit: "",
        editBore: "",
        start_point: "ld",
        test: "test",
        isResetValues: true
    },
    paz: {
        w: "",
        type_paz: "",
        z: "",
        w_paz: "",
        d1: "",
        d_side_start: "",
        d_side_end: "",
        side_from: "",
        formula_z_paz: "",
        formula_w_paz: "",
        formula_d_paz: "",
        formula_d_side_start: "",
        formula_d_side_end: "",
        active_f_z: false,
        active_f_w_paz: false,
        active_f_d: false,
        active_f_d_side_start: false,
        active_f_d_side_end: false
    },
    hem: {
        material: null,
        typeHem: null,
        band: null,
        wide_plank: 120,
        isEdit: null,
        editHem: null,
        hem_txt_back: true
    },
    cut_to: {
        t: "",
        b: "",
        r: "",
        l: "",
        formula_t: "",
        formula_b: "",
        formula_r: "",
        formula_l: "",
        active_f_t: false,
        active_f_b: false,
        active_f_r: false,
        active_f_l: false,
        isEdit: false
    },
    trough: {
        x1: "",
        y1: "",
        z: "",
        l: "",
        w: "",
        type_form: "strait",
        r: "",
        formula_x: "",
        formula_y: "",
        formula_z: "",
        formula_l: "",
        formula_w: "",
        active_f_x: false,
        active_f_y: false,
        active_f_z: false,
        active_f_l: false,
        active_f_w: false,
        isEdit: false
    },
    template: {
        form: [],
        values: {},
        id: null
    },
    editTemplate: {
        active: false,
        operationData: null,
        band: {
            result: null,
            name: null,
            t: null
        },
        glass: {result: null, name: null}
    },


};

const OperationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPERATIONS_FILTER_ADD_ALL: {
            let {part} = action.payload;
            let operationsFilter = {};
            for (const property in part.operations) {
                let values = part.operations[property]?.map(val => val.id);
                operationsFilter[property] = values;
            }
            let srez = part?.srez && (part.srez.b.ugol != "" || part.srez.l.ugol != "" || part.srez.r.ugol != "" || part.srez.t.ugol != "") ? part.srez : false;
            if (srez) {
                operationsFilter['srez'] = [-1]
            }
            return {
                ...state,
                operationsFilter: operationsFilter
            };
        }
        case OPERATIONS_FILTER_REMOVE_ALL: {
            return {
                ...state,
                operationsFilter: {
                    bore: [],
                    paz: [],
                    frez: [],
                    trough: [],
                    // rest all
                    cut_to: [],
                    hem: [],
                    srez: []
                }
            };
        }
        case OPERATIONS_UPDATE_FILTER_SELECTED: {
            let operationsFilter = {...state.operationsFilter};
            // let { type, values, action: actionEvent, operationsState } = action.payload;
            return {
                ...state,
                operationsFilter: {
                    ...operationsFilter,
                    ...action.payload
                }
            }
        }
        case OPERATIONS_FILTER_SELECTED : {
            let operationsFilter = {...state.operationsFilter};
            let {type, values, action: actionEvent, operationsState} = action.payload;
            let values_num = values?.map(id => Number(id))
            if (!AVAILABLE_SELECTED_FILTER_OPERATIONS_KEYS.includes(type.trim()) && !actionEvent === 'reset') {

                return {
                    ...state
                };
            }
            if (!(type in state.operationsFilter) && !actionEvent === 'reset') {
                return {
                    ...state,
                    operationsFilter: {
                        ...state.operationsFilter,
                        [type]: values_num
                    }
                };
            }
            if (actionEvent === "add") {

                let valuesUpdate = operationsFilter[type] ? operationsFilter[type]?.filter(id => !values_num?.find(val => Number(id) === Number(val))) : []
                operationsFilter = {
                    ...operationsFilter,
                    [type]: [...valuesUpdate, ...values_num]
                };
            }
            if (actionEvent === "remove") {
                operationsFilter = {
                    ...operationsFilter,
                    [type]: operationsFilter[type]?.filter(id => {
                        return !values_num?.includes(Number(id));
                        // return !values?.find(val => Number(id) === Number(val));
                    })
                };
            }
            if (actionEvent === 'reset') {
                operationsFilter = {
                    ...operationsFilter,
                    [type]: values,
                }
            }
            return {
                ...state,
                operationsFilter: {...operationsFilter}
            };
        }
        case OPERATIONS_SELECTED_ALL_OFF: {
            return {
                ...state,
                operationsSelected: {}
            };
        }
        case OPERATIONS_SELECTED_ADD: {
            // const availableKeys = ['bore','cut_to','frez', 'hem','paz',"trough"];
            let operations = state.operationsSelected;

            let {operation: keyOperation = "", operation_id = -1} = action.payload;
            if (!AVAILABLE_SELECTED_OPERATIONS_KEYS.includes(keyOperation.trim())) {
                console.log("mistake: key not available", keyOperation);
                return {
                    ...state
                };
            }
            if ((keyOperation in operations) && Array.isArray(operations[keyOperation])) {
                let updateOperationId = new Set([...operations[keyOperation], operation_id]);
                operations = {
                    ...operations,
                    [keyOperation]: Array.from(updateOperationId)
                };
            } else {
                operations = {
                    ...operations,
                    [keyOperation]: [operation_id]
                };
            }
            return {
                ...state,
                operationsSelected: {
                    ...state.operationsSelected,
                    ...operations
                }
            };
        }
        case OPERATIONS_SELECTED_DELETE: {
            // const availableKeys = [  'bore','cut_to','frez', 'hem','paz',"trough"];
            let operations = state.operationsSelected;
            let {operation: keyOperation = "", operation_id = -1} = action.payload;
            if (!AVAILABLE_SELECTED_OPERATIONS_KEYS.includes(keyOperation.trim()) || !(keyOperation in operations)) {
                console.log("mistake: key not available or not key in object", keyOperation, operations);
                return {
                    ...state
                };
            }
            if (Array.isArray(operations[keyOperation])) {
                operations = {
                    ...operations,
                    [keyOperation]: operations[keyOperation].filter(item => Number(item) !== Number(operation_id))
                };
            }
            return {
                ...state,
                operationsSelected: {
                    ...state.operationsSelected,
                    ...operations
                }
            };
        }
        case OPENING_DETAILS_FROM_PART: {
            return {
                ...state,
                openingDetailsFromPart: {...action.payload}
            };
        }
        case SET_OPERATION_IMAGE_SVG_DATA:
            return {
                ...state,
                partImage: action.image
            };
        case CHNAGE_BORE_FORM_VALUE:
            const bore = {...state.bore};
            bore[action.input] = action.value;
            return {
                ...state,
                bore: bore
            };
        case CHANGE_PAZ_FORM_VALUE:
            const paz = {...state.paz};
            paz[action.input] = action.value;
            return {
                ...state,
                paz: paz
            };
        case CHANGE_HEM_FORM_VALUE:
            const hem = {...state.hem};
            hem[action.input] = action.value;
            return {
                ...state,
                hem: hem
            };
        case CHANGE_CUT_FORM_VALUE:
            const cut_to = {...state.cut_to};
            cut_to[action.input] = action.value;
            return {
                ...state,
                cut_to: cut_to
            };
        case CHANGE_TEMPLATE_EDIT_FORM_VALUE:
            const editTemplate = {...state.editTemplate};
            editTemplate[action.input] = action.value;
            return {
                ...state,
                editTemplate: editTemplate
            };
        case CHANGE_OPERATIONS_MODAL_VALUE:
            return {
                ...state,
                editTemplate: initialState.editTemplate
            };
        case CHANGE_TROUGH_FORM_VALUE:
            const trough = {...state.trough};
            trough[action.input] = action.value;
            return {
                ...state,
                trough: trough
            };
        case CHANGE_BORE_FORM_VALUE_FORMULA:
            const bore_formula = {...state.bore};
            const input = action.input === "x" || action.input === "y" ? action.input + "1" : action.input;
            bore_formula[input] = _calculateFormula(action.value, action.part, action.productionsConst);
            bore_formula["formula_" + action.input] = action.value;
            return {
                ...state,
                bore: bore_formula
            };
        case CHANGE_PAZ_FORM_VALUE_FORMULA:
            const paz_formula = {...state.paz};
            const paz_input = action.input === "d" ? action.input + "1" : action.input;
            paz_formula[paz_input] = _calculateFormula(action.value, action.part, action.productionsConst);
            paz_formula["formula_" + action.input + "_paz"] = action.value;
            return {
                ...state,
                // bore:paz_formula
                paz: paz_formula
            };
        case CHANGE_CUT_FORM_VALUE_FORMULA:
            const cut_to_formula = {...state.cut_to};
            const cut_to_input = action.input;
            cut_to_formula[cut_to_input] = _calculateFormula(action.value, action.part, action.productionsConst);
            cut_to_formula["formula_" + action.input] = action.value;
            return {
                ...state,
                cut_to: cut_to_formula
            };
        case CHANGE_TROUGH_FORM_VALUE_FORMULA:
            const trough_formula = {...state.trough};
            const trough_input = action.input === "x" || action.input === "y" ? action.input + "1" : action.input;

            trough_formula[trough_input] = _calculateFormula(action.value, action.part, action.productionsConst);
            trough_formula["formula_" + action.input] = action.value;
            return {
                ...state,
                trough: trough_formula
            };
        case CHANGE_OPERATIONS_SIDE:
            return {
                ...state,
                operationSide: action.side
            };
        case SET_HEMS_TYPES_DATA:
            return {
                ...state,
                hemTypes: action.hemTypes
            };
        case SET_TEMPLATES_TREE_DATA:
            return {
                ...state,
                templatesTree: action.templates
            };
        case CLEAN_BORE_FORM:
            return {
                ...state,
                bore: {
                    x1: "",
                    y1: "",
                    d: "",
                    z: "",
                    across_x: "",
                    across_y: "",
                    formula_x: "",
                    formula_y: "",
                    formula_z: "",
                    formula_d: "",
                    active_f_x: false,
                    active_f_y: false,
                    active_f_z: false,
                    active_f_d: false,
                    isEdit: "",
                    editBore: "",
                    start_point: action?.start_point ?? "ld",
                    isResetValues: state?.bore?.isResetValues ?? false
                },
                operationSide: "f"
            };
        case CLEAN_PAZ_FORM:
            return {
                ...state,
                paz: {...initialState.paz}
            };
        case CLEAN_HEM_FORM:
            return {
                ...state,
                hem: {...initialState.hem}
            };
        case CLEAN_CUT_FORM:
            return {
                ...state,
                cut_to: {...initialState.cut_to}
            };
        case CLEAN_TROUGH_FORM:
            return {
                ...state,
                trough: {...initialState.trough}
            };
        case SET_TEMPLATE_FORM_DATA:
            return {
                ...state,
                template: {
                    form: action.form,
                    values: action.values,
                    isFrez: action.isFrez,
                    alert: action?.alert,
                    id: action.id
                }
            };
        case CHANGE_TEMPLATE_FORM_VALUE_DATA:
            return {
                ...state,
                template: {
                    ...state.template,
                    values: {
                        ...action.values
                    }
                }
            };

        case EDIT_BORE_DATA:
            return {
                ...state,
                bore: {
                    x1: action.bore.x1,
                    y1: action.bore.y1,
                    d: action.bore.d,
                    z: action.bore.z,
                    side: action.bore.side,
                    across_x: action.bore.f_cx,
                    across_y: action.bore.f_cy,
                    formula_x: action.bore.f_x1,
                    formula_y: action.bore.f_y1,
                    formula_z: action.bore.f_z,
                    active_f_x: action.bore.f_x1 && action.bore.f_x1.length > 0,
                    active_f_y: action.bore.f_y1 && action.bore.f_y1.length > 0,
                    active_f_z: action.bore.f_z && action.bore.f_z.length > 0,
                    isEdit: true,
                    start_point: action?.bore?.start_point ?? "ld",
                    id: action.bore.id
                },
                operationSide: action.bore.side
            };
        case EDIT_PAZ_DATA:
            return {
                ...state,
                paz: {
                    w: action.paz.w,
                    type_paz: action.paz.type_paz,
                    z: action.paz.z,
                    w_paz: action.paz.w_paz,
                    d1: action.paz.d1,
                    d_side_start: action.paz.d_side_start,
                    d_side_end: action.paz.d_side_end,
                    side_from: action.paz.side_from,
                    formula_z_paz: action.paz.f_z,
                    formula_w_paz: action.paz.f_w,
                    formula_d_paz: action.paz.f_w_paz,
                    formula_d_side_start: action.paz.f_d_side_start,
                    formula_d_side_end: action.paz.f_d_side_end,
                    active_f_z: action.paz.f_z && action.paz.f_z.length > 0,
                    active_f_w_paz: action.paz.f_w && action.paz.f_w.length > 0,
                    active_f_d: action.paz.f_w_paz && action.paz.f_w_paz.length > 0,
                    active_f_d_side_start: action.paz.f_d_side_start && action.paz.f_d_side_start.length > 0,
                    active_f_d_side_end: action.paz.f_d_side_end && action.paz.f_d_side_end.length > 0,
                    isEdit: true,
                    id: action.paz.id
                },
                operationSide: action.paz.side
            };
        case EDIT_HEM_DATA:
            return {
                ...state,
                hem: {
                    material: action.hem.material,
                    typeHem: action.hem.typeHem,
                    band: action.hem.band,
                    wide_plank: action.hem.wide_plank,
                    hem_txt_back: action.hem.hem_txt_back,
                    isEdit: true,
                    id: action.hem.id
                },
                operationSide: action.hem.side
            };
        case EDIT_CUT_DATA: {
            return {
                ...state,
                cut_to: {
                    t: action.cut_to.t,
                    b: action.cut_to.b,
                    r: action.cut_to.r,
                    l: action.cut_to.l,
                    formula_t: action.cut_to.f_t,
                    formula_b: action.cut_to.f_b,
                    formula_r: action.cut_to.f_r,
                    formula_l: action.cut_to.f_l,
                    active_f_t: action.cut_to?.f_t?.length > 0,
                    active_f_b: action.cut_to?.f_b?.length > 0,
                    active_f_r: action.cut_to?.f_r?.length > 0,
                    active_f_l: action.cut_to?.f_l?.length > 0,
                    isEdit: true,
                    id: action.cut_to.id
                },
                operationSide: action.cut_to.side
            };
        }
        case EDIT_TROUGH_DATA:
            return {
                ...state,
                trough: {
                    x1: action.trough.x1,
                    y1: action.trough.y1,
                    z: action.trough.z,
                    l: action.trough.l,
                    w: action.trough.w,
                    r: action.trough.r,
                    type_form: action.trough.type_form,
                    formula_x: action.trough.formula_x,
                    formula_y: action.trough.formula_y,
                    formula_z: action.trough.formula_z,
                    formula_l: action.trough.formula_l,
                    formula_w: action.trough.formula_w,
                    active_f_x: action.trough?.formula_x?.length > 0,
                    active_f_y: action.trough?.formula_y?.length > 0,
                    active_f_z: action.trough?.formula_z?.length > 0,
                    active_f_l: action.trough?.formula_l?.length > 0,
                    active_f_w: action.trough?.formula_w?.length > 0,
                    isEdit: true,
                    id: action.trough.id
                },
                operationSide: action.trough.side
            };
        case EDIT_TEMPLATE_DATA:
            return {
                ...state
            };
        case SET_FIRM_TOOLS:
            return {
                ...state,
                tools: action.tools
            };
        case OPERATIONS_PARTS_ERROR: {
            return {
                ...state,
                parts_error: action.payload
            }
        }
        default:
            return state;
    }
};
export const onOperationsAddAllOfPart = ({part}) => {
    return {
        type: OPERATIONS_FILTER_ADD_ALL,
        payload: {part}
    }
}
export const onOperationsRemoveAllOfPart = () => {
    return {
        type: OPERATIONS_FILTER_REMOVE_ALL
    }
}
export const onOperationToggleFilter = ({type, action = "add", values = []}) => {
    return {
        type: OPERATIONS_FILTER_SELECTED,
        payload: {
            type, action, values
        }
    };
};
export const onOperationUpdateFilter = (payload) => {
    return {
        type: OPERATIONS_UPDATE_FILTER_SELECTED,
        payload: payload
    };
};
export const onResetOperationsSelected = () => {
    return {
        type: OPERATIONS_SELECTED_ALL_OFF
    };
};

// @params payload
// operation: string
// operation_id: number
export const onOperationsSelectedAdd = (payload) => {
    // debugger;
    return {
        type: OPERATIONS_SELECTED_ADD,
        payload: payload
    };
};
// @params payload
// operation: string
// operation_id: number
export const onOperationsSelectedDelete = (payload) => {
    return {
        type: OPERATIONS_SELECTED_DELETE,
        payload: payload
    };
};

export const setOpeningDetailsFromPart = ({partId, goodsId}) => {
    return {
        type: OPENING_DETAILS_FROM_PART,
        payload: {partId, goodsId}
    };
};

export const setOperationsPartImageDataAC = (image) => {
    return {
        type: SET_OPERATION_IMAGE_SVG_DATA,
        image: image
    };
};
export const setFirmToolsAC = (tools) => {
    return {
        type: SET_FIRM_TOOLS,
        tools: tools
    };
};
export const changeOperationsSideAC = (side) => {
    return {
        type: CHANGE_OPERATIONS_SIDE,
        side: side
    };
};
export const setHemsTypesAC = (hemTypes) => {
    return {
        type: SET_HEMS_TYPES_DATA,
        hemTypes: hemTypes
    };
};
export const setTemplatesTreeAC = (templates) => {
    return {
        type: SET_TEMPLATES_TREE_DATA,
        templates: templates
    };
};
export const setTemplateFormAC = (template, id) => {

    return {
        type: SET_TEMPLATE_FORM_DATA,
        form: template.form,
        values: template.values,
        isFrez: template.isFrez,
        alert: template?.alert ?? null,
        id: id
    };
};
export const changeTemplateFormValueAC = (values) => {
    return {
        type: CHANGE_TEMPLATE_FORM_VALUE_DATA,
        values: values
    };
};


/// ----------------------------------- BORE ----------------------------------- ///

export const changeBoreFormValueAC = (input, value) => {
    return {
        type: CHNAGE_BORE_FORM_VALUE,
        input: input,
        value: value
    };
};
export const changeBoreFormValueFormulaAC = (input, value, part, productionsConst) => {
    return {
        type: CHANGE_BORE_FORM_VALUE_FORMULA,
        input: input,
        value: value,
        part: part,
        productionsConst
    };
};
export const cleanBoreForm = (start_point) => {
    return {
        type: CLEAN_BORE_FORM,
        start_point: start_point
    };
};
export const editBoreDataAC = (bore) => {
    return {
        type: EDIT_BORE_DATA,
        bore: bore
    };
};

/// ----------------------------------- PAZ ----------------------------------- ///

export const changePazFormValueAC = (input, value) => {
    return {
        type: CHANGE_PAZ_FORM_VALUE,
        input: input,
        value: value
    };
};
export const changePazFormValueFormulaAC = (input, value, part, productionsConst) => {
    return {
        type: CHANGE_PAZ_FORM_VALUE_FORMULA,
        input: input,
        value: value,
        part: part,
        productionsConst
    };
};
export const cleanPazForm = () => {
    return {
        type: CLEAN_PAZ_FORM
    };
};
export const editPazDataAC = (paz) => {
    return {
        type: EDIT_PAZ_DATA,
        paz: paz
    };
};

/// ----------------------------------- HEM ----------------------------------- ///

export const changeHemFormValueAC = (input, value) => {
    return {
        type: CHANGE_HEM_FORM_VALUE,
        input: input,
        value: value
    };
};
export const cleanHemForm = () => {
    return {
        type: CLEAN_HEM_FORM
    };
};
export const editHemDataAC = (hem) => {
    return {
        type: EDIT_HEM_DATA,
        hem: hem
    };
};

/// ----------------------------------- CUT ----------------------------------- ///

export const changeCutFormValueAC = (input, value) => {
    return {
        type: CHANGE_CUT_FORM_VALUE,
        input: input,
        value: value
    };
};
export const changeCutFormValueFormulaAC = (input, value, part, productionsConst) => {
    return {
        type: CHANGE_CUT_FORM_VALUE_FORMULA,
        input: input,
        value: value,
        part: part,
        productionsConst
    };
};
export const cleanCutForm = () => {
    return {
        type: CLEAN_CUT_FORM
    };
};
export const editCutDataAC = (cut_to) => {
    return {
        type: EDIT_CUT_DATA,
        cut_to: cut_to
    };
};

/// ----------------------------------- TROUGH ----------------------------------- ///

export const changeTroughFormValueAC = (input, value) => {
    return {
        type: CHANGE_TROUGH_FORM_VALUE,
        input: input,
        value: value
    };
};
export const changeTroughFormValueFormulaAC = (input, value, part, productionsConst) => {
    return {
        type: CHANGE_TROUGH_FORM_VALUE_FORMULA,
        input: input,
        value: value,
        part: part,
        productionsConst
    };
};
export const cleanTroughForm = () => {
    return {
        type: CLEAN_TROUGH_FORM
    };
};
export const editTroughDataAC = (trough) => {
    return {
        type: EDIT_TROUGH_DATA,
        trough: trough
    };
};
export const editTemplateDataAC = (template) => {
    return {
        type: EDIT_TEMPLATE_DATA,
        template: template
    };
};
export const editTemplateFormDataAC = (input, value) => {
    return {
        type: CHANGE_TEMPLATE_EDIT_FORM_VALUE,
        input: input,
        value: value
    };
};

export const onSetOperationPartsError = (payload) => {
    return {
        type: OPERATIONS_PARTS_ERROR,
        payload: payload
    }
}
/// ----------------------------------- ----------------------------------- ///
const dispatchedPartImageCodeAPI = async (order, part_id, dispatch) => {
    try {
        const response_previews = await apiImages.getPartImage(order, part_id);
        if (response_previews?.hasOwnProperty('error') && response_previews?.error || api._getErrors()) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
            dispatch(changeLoaderStatusAC(false));
            return;
        }
        dispatch(setOperationsPartImageDataAC(response_previews));
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        checkErrorsAPI("", "api: /images/, M:P, (getPartImage)");
    }
}
export const getPartImageCodeThunk = (part_id) => async (dispatch, getState) => {
        const order = getState().order.order;
        const currentPart = order?.part?.find(part => Number(part?.id) === Number(part_id));
        try {
            dispatch(changeLoaderStatusAC(true));
            dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Формируем чертеж детали...")));
            let images_internal = !!Number(localStorage.getItem(LS_IMAGES_INTERNAL));
            if (images_internal) {
                const bands_type = order.bands_type;
                const materials = order.material;
                const bands = order.band;
                const client = order?.client;
                const part = await api.getPartSizes(currentPart, bands_type, materials, bands, client);
                let order_data = {...order, part: [part], type_svg: 'no_size'};
                const images_res = blueprints.wholeBlueprint(part_id, JSON.parse(JSON.stringify(order_data)));
                dispatch(setOperationsPartImageDataAC({svg: images_res}));
                dispatch(changeLoaderStatusAC(false));
                return
            }
            await dispatchedPartImageCodeAPI(order, part_id, dispatch);
            dispatch(changeLoaderStatusAC(false));

        } catch
            (e) {
            let errors = e?.hasOwnProperty('error') ? e?.errors : e?.message;
            await dispatchedPartImageCodeAPI(order, part_id, dispatch);
            dispatch(changeLoaderStatusAC(false));
        }

    }
;
export const getFirmToolsThunk = () => async (dispatch, getState) => {

    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получаем данные по инструментам и станкам...")));
    api.getTools()
        .then(res => {
            if (res) {
                dispatch(setFirmToolsAC(res));
                dispatch(changeLoaderStatusAC(false));
            } else {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
            }
        }).catch(e => {
        checkErrorsAPI("", "api:/tools/equipment/?firm=, M:G");
        dispatch(changeLoaderStatusAC(false));
    });
};
export const editOperationThunk = (operation, type, editTemplate = false) => (dispatch, getState) => {
    switch (type) {
        case "bore":
            dispatch(editBoreDataAC(operation));
            dispatch(changeOperationsModalValue("aside", type));
            break;
        case "srez":
            // dispatch(editSrezDataAC(operation));
            dispatch(changeOperationsModalValue("aside", type));
            break;
        case "paz":
            dispatch(editPazDataAC(operation));
            dispatch(changeOperationsModalValue("aside", type));
            break;
        case "hem":
            dispatch(editHemDataAC(operation));
            dispatch(changeOperationsModalValue("aside", type));
            break;
        case "cut_to":
            dispatch(editCutDataAC(operation));
            dispatch(changeOperationsModalValue("aside", type));
            break;
        case "trough":
            dispatch(editTroughDataAC(operation));
            dispatch(changeOperationsModalValue("aside", type));
            break;
        case "template":
            if (editTemplate?.hasOwnProperty("part") && (operation?.hasOwnProperty("xnc_template") || operation?.hasOwnProperty("new_xnc_template"))) {
                dispatch(editTemplateFormThunk(operation, editTemplate?.part));

            } else {

                let values = operation?.hasOwnProperty("inputs") ? {
                    db_id: operation?.inputs.db_id,
                    t: operation?.inputs?.t
                } : {};
                dispatch(setTemplateFormAC({form: [], values, isFrez: true}, operation.id));
            }
            dispatch(changeTemplatesModalValue("isOpen", false));
            dispatch(changeOperationsModalValue("aside", type));
            dispatch(editTemplateFormDataAC("active", true));
            dispatch(editTemplateFormDataAC("operationData", operation));
            break;
        default: {
            console.log("not type");
        }
    }

};
export const getHemTypesDataThunk = () => (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получаем данные о возможных типах сшивки...")));
    api.getHemTypes()
        .then(res => {
            if (res) {
                dispatch(setHemsTypesAC(res));
                dispatch(changeLoaderStatusAC(false));
            } else {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
            }
        }).catch(e => {
        checkErrorsAPI("", "api:doubles, M:G");
        dispatch(changeLoaderStatusAC(false));
    });
};

export const getTemplatesTreeThunk = (glassTree = false) => (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получаем список доступных шаблонов...")));
    api.getTemplatesTree(glassTree)
        .then(res => {
            if (res) {
                dispatch(setTemplatesTreeAC(res));
                dispatch(changeLoaderStatusAC(false));
            } else {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
            }
        }).catch(e => {
        checkErrorsAPI("", "api:/templates/{url}, M:G");
        dispatch(changeLoaderStatusAC(false));
    });
};

export const getTemplateFormThunk = (id, part) => (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получаем перечень необходимых параметров шаблона...")));
    api.getTemplateForm(id)
        .then(res => {
            if (res) {
                dispatch(setTemplateFormAC({
                    ..._constructorTemplate(res.data, res.isFrez, part),
                    alert: res?.alert ?? null
                }, id));
                dispatch(changeOperationsModalValue("aside", "template"));
                dispatch(changeTemplatesModalValue("isOpen", false));
                dispatch(changeLoaderStatusAC(false));
            } else {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
            }
        }).catch(e => {
        checkErrorsAPI("", "api:/templates/{id}/?firm={firm}, M:G");
        dispatch(changeLoaderStatusAC(false));
    });
};
export const editTemplateFormThunk = (operations, part) => (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получаем перечень необходимых параметров шаблона...")));
    let idTemplate = operations?.xnc_template ?? operations?.new_xnc_template;
    let idOperation = operations.id;
    api.getTemplateForm(idTemplate)
        .then(res => {
            if (res) {
                const template = _constructorEditTemplate(res.data, res.isFrez, part, idOperation, idTemplate, res.alert);

                dispatch(setTemplateFormAC(template, idTemplate));
                // dispatch(changeOperationsModalValue('aside', 'template'));
                // dispatch(changeTemplatesModalValue('isOpen', false))
                dispatch(changeLoaderStatusAC(false));
            } else {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
            }

        }).catch(e => {
        checkErrorsAPI("", "api:/templates/{id}/?firm={firm}, M:G");
        dispatch(changeLoaderStatusAC(false));
    });
};


export const addTemplateOperationThunk = (id, part, form) => (dispatch, getState) => {
    const band = getState().order.order.band;
    const side = getState().operations.operationSide;
    dispatch(changeLoaderStatusAC(true));
    let images_internal = !!Number(localStorage.getItem(LS_IMAGES_INTERNAL));

    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получение операций по шаблону...")));
    api.getTemplateOperations(id, band, part, form)
        .then(res => {
            if (res) {
                dispatch(addNewTemplateOperationsAC(res, part.id, side, form));
                if (!images_internal) dispatch(getPartSizesThunk(part));
                // dispatch(changeOperationsModalValue('aside', 'history'));
                dispatch(changeLoaderStatusAC(false));
            } else {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
            }
        }).catch(e => {
        checkErrorsAPI("", "api:/templates, M:P");
        dispatch(changeLoaderStatusAC(false));
    });
};

export const editTemplateOperationThunk = (id, part, form, frezId) => async (dispatch, getState) => {
    const band = getState().order.order.band;
    const side = getState().operations.operationSide;
    // const part = getState().order.order.part.find(i => Number(i.id) === Number(partId))
    let images_internal = !!Number(localStorage.getItem(LS_IMAGES_INTERNAL));
    dispatch(changeLoaderStatusAC(true));
    api.getTemplateOperations(id, band, part, form)
        .then(res => {
            if (res) {
                dispatch(editNewTemplateOperationsAC(res, part.id, side, form, frezId));
                if (!images_internal) dispatch(getPartSizesThunk(part));
                dispatch(changeLoaderStatusAC(false));
            } else {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
            }
        }).catch(e => {
        dispatch(checkErrorsAPI("", "api:/templates, M:P"))
        dispatch(changeLoaderStatusAC(false));
    });
};


export default OperationsReducer;